import Input from "./Input";
import Button from "./Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {useDispatch, useSelector, useStore} from "react-redux";
import {
	selectProjectAuthor, selectProjectBuilding, selectProjectFloor, selectProjectLine,
	selectProjectName, selectProjectRoom, setConfigurationID,
	setProjectAuthor, setProjectBuilding, setProjectFloor, setProjectLine,
	setProjectName, setProjectRoom
} from "../features/configuration/configurationSlice";
import {Link} from "react-router-dom";
import {setShowSavingModal} from "../features/products/productsSlice";
import {saveConfiguration} from "../services/service";
import Translator, { imperativeTranslateMessage } from "./Translator";
import { useIntl } from "react-intl";

export default function SavingModal({isResultPage}) {
	const [projectNameInput, setProjectNameInput] = useState('');
	const [projectAuthorInput, setProjectAuthorInput] = useState('');
	const [projectBuildingInput, setProjectBuildingInput] = useState('');
	const [projectFloorInput, setProjectFloorInput] = useState('');
	const [projectRoomInput, setProjectRoomInput] = useState('');
	const [projectLineInput, setProjectLineInput] = useState('');
	
	const projectName = useSelector(selectProjectName);
	const projectAuthor = useSelector(selectProjectAuthor);
	const projectBuilding = useSelector(selectProjectBuilding);
	const projectFloor = useSelector(selectProjectFloor);
	const projectRoom = useSelector(selectProjectRoom);
	const projectLine = useSelector(selectProjectLine);
	
	const dispatch = useDispatch();
	const store = useStore();

	const intl = useIntl();
	
	useEffect( () => {
		if (projectName && projectName !== projectNameInput) {
			setProjectNameInput(projectName);
		}
		if (projectAuthor && projectAuthor !== projectAuthorInput) {
			setProjectAuthorInput(projectAuthor);
		}
		if (projectBuilding && projectBuilding !== projectBuildingInput) {
			setProjectBuildingInput(projectBuilding);
		}
		if (projectFloor && projectFloor !== projectFloorInput) {
			setProjectFloorInput(projectFloor);
		}
		if (projectRoom && projectRoom !== projectRoomInput) {
			setProjectRoomInput(projectRoom);
		}
		if (projectLine && projectLine !== projectLineInput) {
			setProjectLineInput(projectLine);
		}
	}, [])
	
	return (
		<div className="fixed inset-0 bg-overlay z-50 flex flex-wrap justify-center content-center">
			<div className={"py-7 px-10 relative shadow-line-number w-[500px] " + (isResultPage ? 'bg-white text-black' : 'bg-black text-white ')}>
				<FontAwesomeIcon className="cursor-pointer text-22 absolute right-3 top-2" icon={['fal', 'fa-times']} onClick={() => dispatch(setShowSavingModal(false))}/>
				<div className="font-medium text-20">
					{isResultPage && <Translator id="popup.save.configuration.header.alternate" />}
					{!isResultPage && <Translator id="popup.save.configuration.header" />}
				</div>
				
				{!isResultPage && <div><Translator id="popup.save.configuration.subheader" /></div>}
				
				<div className="mt-5">
					<div className={"font-medium text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.save.configuration.name" /></div>
					<Input onChange={(e) => setProjectNameInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.save.configuration.name.placeholder")} value={projectNameInput}/>
				</div>
				<div className="my-5">
					<div className={"font-medium text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.save.configuration.author" /></div>
					<Input onChange={(e) => setProjectAuthorInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.save.configuration.author.placeholder")} value={projectAuthorInput}/>
				</div>
				<div className="my-5">
					<div className={"font-medium text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.save.configuration.building" /></div>
					<Input onChange={(e) => setProjectBuildingInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.save.configuration.building.placeholder")} value={projectBuildingInput}/>
				</div>
				<div className="my-5">
					<div className={"font-medium text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.save.configuration.floor" /></div>
					<Input onChange={(e) => setProjectFloorInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.save.configuration.floor.placeholder")} value={projectFloorInput}/>
				</div>
				<div className="my-5">
					<div className={"font-medium text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.save.configuration.room" /></div>
					<Input onChange={(e) => setProjectRoomInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.save.configuration.room.placeholder")} value={projectRoomInput}/>
				</div>
				<div className="my-5">
					<div className={"font-medium text-12 " + (isResultPage ? 'text-black' : 'text-layer-grey')}><Translator id="popup.save.configuration.line" /></div>
					<Input onChange={(e) => setProjectLineInput(e.target.value)} className={"bg-transparent my-2 placeholder-disabled-grey outline-none border-b w-full " + (isResultPage ? 'border-b-black' : 'border-b-white')} placeholder={imperativeTranslateMessage(intl, "popup.save.configuration.line.placeholder")} value={projectLineInput}/>
				</div>
				<div className="text-right">
					<Link to="/result">
						<Button className={"text-20 border inline-block py-2 px-9 cursor-pointer transition-all duration-300 hover:brightness-75 " + (isResultPage ? 'border-black' : 'border-white')} onClick={() => {
							dispatch(setProjectName(projectNameInput));
							dispatch(setProjectAuthor(projectAuthorInput));
							dispatch(setProjectBuilding(projectBuildingInput));
							dispatch(setProjectFloor(projectFloorInput));
							dispatch(setProjectRoom(projectRoomInput));
							dispatch(setProjectLine(projectLineInput));
							saveConfigurationToServer();
							dispatch(setShowSavingModal(false));
						}}>
							{isResultPage && <Translator id="popup.save.configuration.change.button"/>}
							{!isResultPage && <Translator id="popup.save.configuration.save.button"/>}
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
	
	/**
	 * Save configuration function wrapper that allows access to store (only allowed inside of a component)
	 */
	function saveConfigurationToServer() {
		saveConfiguration(store.getState()).then(result => {
			dispatch(setConfigurationID(result.data.id));
		});
	}
}